<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5142 18.5362L12.5237 18.5428C13.3501 19.069 14.3313 19.2975 15.3052 19.1906C16.279 19.0836 17.1872 18.6476 17.8797 17.9546L18.4813 17.3531C18.6146 17.2198 18.7204 17.0616 18.7925 16.8875C18.8647 16.7134 18.9018 16.5268 18.9018 16.3383C18.9018 16.1498 18.8647 15.9632 18.7925 15.7891C18.7204 15.6149 18.6146 15.4568 18.4813 15.3235L15.9439 12.788C15.8107 12.6547 15.6525 12.549 15.4784 12.4768C15.3043 12.4047 15.1176 12.3675 14.9292 12.3675C14.7407 12.3675 14.554 12.4047 14.3799 12.4768C14.2058 12.549 14.0476 12.6547 13.9144 12.788C13.6453 13.057 13.2805 13.2081 12.9001 13.2081C12.5197 13.2081 12.1548 13.057 11.8858 12.788L7.82866 8.72994C7.55971 8.4609 7.40862 8.09606 7.40862 7.71565C7.40862 7.33524 7.55971 6.9704 7.82866 6.70136C7.96197 6.56813 8.06773 6.40994 8.13989 6.23582C8.21204 6.0617 8.24918 5.87507 8.24918 5.6866C8.24918 5.49812 8.21204 5.31149 8.13989 5.13737C8.06773 4.96325 7.96197 4.80506 7.82866 4.67183L5.29222 2.13635C5.02319 1.8674 4.65835 1.71631 4.27794 1.71631C3.89752 1.71631 3.53268 1.8674 3.26365 2.13635L2.6611 2.73794C1.96826 3.43056 1.53244 4.33883 1.42567 5.31267C1.3189 6.2865 1.54755 7.26762 2.07386 8.09392L2.07959 8.10348C4.85914 12.2159 8.40125 15.7574 12.5142 18.5362V18.5362Z" stroke="currentColor" stroke-width="1.47" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconPhoneSolid'
}
</script>
